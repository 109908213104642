import React from 'react';
import { Layout } from '../../components';

const B2CTermsAndConditions = () => (
  <Layout pageTitle="B2C General Terms and Conditions">
    <section className="legal-section bg-dark">
      <div className="container">
        <div className="row">
          <div>
            <h1 className="text-white text-center">
              General Terms and Conditions for B2C
            </h1>
            <div className="text-justify text-white-60 col-lg-10 mx-auto">
              <p className="font-italic text-white text-left">
                Last update on June 14, 2022.
              </p>
              <h3 className="text-white text-left">1. The meaning of terms</h3>
              <p>
                <b>Client/Consumer</b> is a person entering a legal business or
                operating on the market outside his trade, business, craft, or
                professional activity
              </p>
              <p>
                <b>Consumer agreement</b> is an agreement concluded by the
                Consumer and the Company acting within the framework of his
                trade, business, craft, or professional activity, including a
                person acting on behalf of or on behalf of that person.
              </p>

              <h3 className="text-white text-left">
                2. General information about the Services
              </h3>
              <p>
                These General Terms and Conditions represent a binding agreement
                between Cobalt Hills Tech Ltd company for computer activities
                and services, Pod vrh 7, Cernik, Čavle, PIN: 25966171359 (the
                Company) as a service provider and a user of the Services (the
                Consumer) as a service recipient in the field of software
                programming services and other similar activities that are part
                of the Company's services. These General Terms and Conditions
                also represent pre-contractual notices.
              </p>
              <p>
                The General Terms and Conditions are drawn up in accordance with
                the Croatian Law of Obligations, the Consumer Protection Act,
                and all other applicable regulations of the Republic of Croatia
                and in accordance with the rules of the European Union.
              </p>
              <p>
                By ordering the Services, it is considered that the Consumer has
                read, taken note of all the notices, and has given consent to
                these General Terms and Conditions.
              </p>
              <p>
                The Consumer is not eligible to accept these General Terms and
                Conditions or use Company's services if the Consumer is not of
                legal age to form a binding contract with the Company or if the
                Consumer is barred by law to use Company's services.
              </p>
              <p>
                The Company provides the following services:
                <ul>
                  <li>web development</li>
                  <li>mobile development</li>
                  <li>machine learning</li>
                  <li>quality assurance</li>
                  <li>IT consulting</li>
                  <li>education</li>
                </ul>
              </p>
              <p>
                The Services are provided with the care of a good businessman
                and the Consumer will be, for each service, provided with
                detailed and accurate information in an accurate and
                non-misleading way. By accepting these General Terms and
                Conditions, the Consumer accepts the Service Description offered
                to them and confirms that they are familiar with it. The Company
                reserves the right to change or modify the Service Description
                at any time and at its sole discretion, but when the description
                for the Services has been provided to the Consumer and after
                they have requested the Services, changes will not affect the
                Services they have requested.
              </p>

              <h3 className="text-white text-left">3. Terms of use</h3>
              <p>
                These General Terms and Conditions are referred to Consumers
                that are defined as Consumers by the Croatian Consumer
                Protection Act.
              </p>
              <p>
                To provide Services, the Company may require certain
                information. The Consumer must ensure that the information is
                complete and accurate. The Company may suspend or terminate any
                service if the Consumer provides information that is not
                complete and accurate. Consumer warrants that all information
                Consumer provides to the Company are complete and accurate and
                the Consumer indemnifies the Company against any liability that
                may arise as a result of Consumer's failure to provide complete
                and accurate information. The Consumer must immediately notify
                the Company if any of the Consumer information changes.
              </p>
              <p>
                The Consumer agrees that the Company may freely transfer the
                contracted work to other subcontractors. The Company may agree
                to bypass these rules if the nature of the services requires so.
              </p>
              <p>
                The Consumer cannot transfer any of their rights or obligations
                under the Agreement to any other person without the prior
                written consent of the Company. If the Services are delivered by
                the Company and paid in full by the Consumer this term is not
                applicable.
              </p>

              <h3 className="text-white text-left">
                4. Orders and termination
              </h3>
              <p>
                Orders are made in writing or in another method of
                communication.
              </p>
              <p>
                The Consumer can terminate this agreement without giving reasons
                for it if the Services are ordered outside the premises or
                concluded remotely from the date of its conclusion and no later
                than 14 days from the day that the Services have been ordered.
              </p>
              <p>
                The Consumer is not entitled to terminate agreement or services
                if:
                <ul>
                  <li>
                    the Services are fully fulfilled and the Consumer gave
                    explicit prior consent for service delivery and their
                    confirmation that they are aware of the fact that they will
                    lose the right to terminate this agreement if the services
                    are fully fulfilled. It is considered that the Consumer has
                    given their consent when they ordered the services or when
                    they entered into a service contract
                  </li>
                  <li>
                    the Services are made according to the Consumer’s
                    specification or are adapted to the Consumer
                  </li>
                  <li>and in other legally-established cases</li>
                </ul>
              </p>
              <p>
                The Company may also terminate this Agreement with immediate
                effect by notice to the Consumer if:
                <ul>
                  <li>
                    the Consumer commits a material breach of the Agreement and
                    (where such breach is capable of remedy) fails to remedy the
                    breach within 14 days of a written notice from the Company
                  </li>
                  <li>
                    any licence required for the Company to operate the Services
                    is revoked, terminated or modified or, in the case of new
                    licence requirements that prevent the Company from
                    continuing to make the Services available.
                  </li>
                </ul>
              </p>
              <p>
                Termination of the Agreement for any reason does not affect any
                rights that have accrued to either party under the Agreement up
                to the date of its termination.
              </p>
              <p>
                The Company and the Consumer may agree on other terms in their
                mutual Agreement.
              </p>

              <h3 className="text-white text-left">
                5. A right to refuse the conclusion of a contract and/or to
                provide the Services
              </h3>
              <p>
                The Company may refuse to sign a contract or provide Services in
                the following situations:
                <ul>
                  <li>If the Consumer does not pay 2 consecutive invoices</li>
                  <li>
                    If the Consumer uses or wants to use the Services provided
                    for illegal purposes
                  </li>
                  <li>If the Consumer damages the reputation of the Company</li>
                  <li>In other statutory cases</li>
                </ul>
              </p>

              <h3 className="text-white text-left">
                6. Place of service, delivery time, intellectual property,
                confidential information
              </h3>
              <p>
                The Company determines the place, the time, and how the Services
                are performed. If it is necessary, the Consumer must also be
                able to provide access to its premises and equipment, and
                respond to inquiries of the Company.
              </p>
              <p>
                The Company undertakes to provide its Services within a
                reasonable and/or agreed timeframe, except in case of a higher
                power, illness, and other justifiable cases of which the
                Consumer will be notified in time.
              </p>
              <p>
                The Company is authorised to make partial delivery of the
                Services the nature of the Services requires a different kind of
                delivery.
              </p>
              <p>
                Any information or work in progress, trade secrets, or any other
                materials related to the business or project of the Company,
                including, without limitation, all computer codes (source codes,
                objects, and executable) and all associated algorithms,
                diagrams, charts, descriptions, and other documentation,
                constitute confidential Information (hereinafter, the
                Confidential Information). The Consumer shall not, either during
                the provision of Services or at any time thereafter, use, copy
                or disclose to any third party any such Confidential
                Information, unless such use, copying, or disclosure has been
                authorised in advance in writing by the Company.
              </p>
              <p>
                The Consumer undertakes to respect the intellectual property
                rights of the Company.
              </p>
              <p>
                If the Services are performed for the Consumer as “work for
                hire” (computer program, code, etc.), upon the payment of the
                Services, the Company will pass all the intellectual property
                rights to the Consumer.
              </p>
              <p>
                The Company and the Company Personnel undertake to respect the
                intellectual property rights of the Consumer.
              </p>
              <p>
                Parties hereby grant each other the right to use and display
                each other’s name and logo ("Trademarks") for promotional means
                on the respective websites or other promotional material,
                however, restricted solely in connection with the Services
                provided. Any usage under this clause shall be done according to
                the proprietor Party’s guidelines as they may be provided from
                time to time. Neither Party shall use the other Party’s
                Trademarks in any manner that could disparage, harm, or
                otherwise damage the other Party’s goodwill in its Trademarks.
                The Party using the Trademarks shall not, at any time, misuse
                the same or present itself as an affiliate or other legal agent
                of the Party whose Trademarks are being used. Any rights and
                linked usage of Trademarks granted under this Section shall be
                immediately discontinued in the event Services are terminated.
              </p>
              <p>
                All content, trademarks, and data on the Company website,
                including software, databases, text, graphics, icons,
                hyperlinks, private information, and designs are the property of
                or licensed to the Company, and as such, are protected from
                infringement by domestic and international legislation and
                treaties. Subject to the rights afforded to the Client in these
                Terms, all other intellectual property rights on this website
                are expressly reserved.
              </p>
              <p>
                Content from the Company website may not be used or exploited
                for any commercial purposes without Company’s prior written
                consent.
              </p>

              <h3 className="text-white text-left">7. Prices and payments</h3>
              <p>
                The Company may charge its service on the bias of billable hours
                and/or as a fixed fee, depending on the agreement with the
                Consumer.
              </p>
              <p>
                The price of the Services is formed according to the scope of
                the Services provided.
              </p>
              <p>
                Prices are delivered to the Consumer through an offer, by
                displaying the price list or other methods, and the prices shown
                in the price list are subject to change. Any change in prices
                that might occur shall be communicated to the Consumer via email
                and/or noted on the Company's website.
              </p>
              <p>
                For the work performed, the Company is obliged to invoice the
                Consumer. Invoices are printed on a computer. The invoice is
                issued when the Service is delivered. If there are multiple
                deliveries of the Services, then the Company may charge the
                Consumer for each delivery or may invoice the Consumer at the
                end of the current month, which is taken as an accounting period
                by the law of the Republic of Croatia.
              </p>
              <p>
                The Consumer is obliged to pay the invoice according to the
                indicative maturity, in case of late payment the Company has all
                rights to ask for interest.
              </p>

              <h3 className="text-white text-left">
                8. Liability, actions and complaints
              </h3>
              <p>
                By accepting these General Terms and Conditions, the Consumer
                accepts that the Company provides Services to them within its
                technical and operational capabilities.
              </p>
              <p>
                The Company is obliged to inform the Consumer about any
                difficulties in the realisation of the contracted work.
              </p>
              <p>
                If due to the Consumer's action for which they are responsible,
                there is an unexpected extension of the performance of the
                Services, the Consumer will not be exempted from their
                obligation to pay the costs to the Company.
              </p>
              <p>
                The Consumer acknowledges and agrees that the availability of
                the Services and Consumer's ability to access and/or use the
                Services may depend upon factors beyond the Company's reasonable
                control for which the Company will not be responsible.
              </p>
              <p>
                The Company cannot guarantee that the Services and/or product of
                the Services will be available to Consumer at all times or free
                from faults or interruptions for which the Company will not be
                responsible.
              </p>
              <p>
                The Company will not be in any way liable for any failure to
                make the Services available to Consumers to the extent that such
                failure results from a technical or another failure on the part
                of any Network Operator or any other event which is beyond the
                Company's reasonable control. The Company will provide all
                services “as they are” and “as available”, and the Company does
                not warrant, represent or guarantee, whether expressly or by
                implication, that any Services are free of errors or
                interruptions, always available and fit for any purpose.
              </p>
              <p>
                The Company is not obliged to pay compensation if the level of
                quality of the Services performed is less than the prescribed
                level of quality of the Service due to objective causes that
                could not have been predicted or avoided or eliminated (higher
                power) or are conditional on the Consumer’s will or actions.
              </p>
              <p>
                The Company is not liable for any damages caused to the Consumer
                and/or third parties for improper or unlawful use of the
                Services and products that are delivered.
              </p>
              <p>
                If the Consumer cancels the order for which the Services have
                already been made, the Consumer is obliged to compensate for the
                damage caused.
              </p>
              <p>
                The Consumer is liable for any omissions or damages which may
                arise from non-compliance with the obligation to provide data on
                the resulting changes, as well as due to non-compliance with the
                obligation to provide any other data requested by the Company
                that is necessary for providing the Services.
              </p>
              <p>
                If the Consumer does not pay the Services in accordance with the
                applicable payment terms, the Company reserves the right to, in
                the Company's sole discretion, suspend Consumer access to the
                Services.
              </p>
              <p>
                The Company is liable for the material defects that may occur on
                the product of the Services at the time of the risk transition
                to the Consumer regardless of whether it was known to the
                Company. The Company is also responsible for those material
                defects that arise after the risk is transferred to the Consumer
                if they are due to the cause that existed before that. It is
                assumed that the deficiency that occurred within one year of the
                transition of the risk to the Consumer existed at the time of
                the risk transition unless the Company proves otherwise or
                otherwise arises from the nature of the matter.
              </p>
              <p>
                The Consumer is not obliged to review the product of the
                Services or to have it inspected but is obliged to inform the
                Company of the existence of visible defects within two months of
                the date on which they discovered the defect (preclusive period
                - under threat of loss of rights), and no later than two years
                after the transfer of the risk to the Consumer. The Company is
                not liable for the shortcomings that arise after two years since
                the delivery of the Service product.
              </p>
              <p>
                The Company is not liable for defects if they were known to the
                Consumer at the time of the Service delivery or could not remain
                unknown to them.
              </p>
              <p>
                If the Consumer has informed the Company of the defect in a
                timely and orderly manner, they are authorised to:
                <ul>
                  <li>require the Company to eliminate the deficiency</li>
                  <li>
                    require the Company to hand over other service products
                    without shortcomings
                  </li>
                  <li>require a proportionate price reduction</li>
                  <li>declare that they are terminating the contract</li>
                </ul>
              </p>
              <p>
                The Consumer has the right to withhold payment of any
                outstanding part of the price until the Company fulfils its
                obligations based on liability for material defects.
              </p>
              <p>
                If the defect is insignificant, the Consumer is not entitled to
                terminate the contract but is entitled to other rights under
                liability for material defects including the right to repair the
                damage.
              </p>

              <h3 className="text-white text-left">9. Other</h3>
              <p>
                The Company reserves the right to change these General Terms and
                Conditions in accordance with changes in legal provisions,
                business policies of the Company, or other circumstances that
                may have an affect on how the Services are provided. In case of
                such changes, the Company will inform the Consumer about it on
                the website and update the date of modification of these General
                Terms and Conditions.
              </p>
              <p>
                For anything that is not governed by these General Terms and
                Conditions or a contract with the Consumer, the relevant
                Croatian legal regulations will be in force.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default B2CTermsAndConditions;
